@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.RegisterBFC {
  width: 100%;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 470px;
  text-align: center;
  margin-top: 48px;

  .bodyText {
    margin-top: 20px;
    margin-bottom: 20px;
    @include full-font($museo-700, 19px, #919191);
  }

  .spinnerModal {
    max-width: 490px;
  }

  .errorBodyText {
    margin-top: 20px;
    margin-bottom: 20px;
    @include full-font($museo-700, 19px, $red);
  }
}