@import "../../index.scss";
@import "../../styles/standard.module.scss";


.container {
    padding: 20px;
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }

  .header {
    margin-top: 30px;
    padding-bottom: 38px;
    @include full-font($museo-700, 28px, $deep-blue);
  }

.instructions {
    @include full-font($museo-700, 18px, $basic-grey);
    padding-bottom: 10px;
}

.apiError {
    @include full-font($museo-500, 16px, $red);
    padding-bottom: 10px;
}

.emailContainer {
    width: 70%;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    .inputGrey {
        margin-top: 20px;
    }
}

.buttonContainer {
    margin-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
}

.forgotLink {
    @include full-font($museo-500, 13px, $purple);
    width: 100%;
    line-height: 20px;
    a {
        float: center;
    }
}

.errorText {
    @include full-font($museo-700, 16px, $red);
  }

  .errorMessage{
    @include full-font($museo-300, 13px, $red);
    text-align: left;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .close {
    height: 22px;
    width: 22px;
    position: absolute;
    color: #444444;;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

.helpText {
  p {
    @include full-font($museo-500, 18px, $basic-grey);
  }
}