@import "./fonts";
@import "./constants";


.inputGrey {
    @include full-font($museo-500, 16px, #333);
    width: 100%;
    font-weight: 400;
    height: 40px;
    border-radius: 8px;
    background-color: $white;
    border: solid 1px $basic-grey;
    padding-left: 15px;
}

input:focus {
    outline: none;
    border: 2px solid #116eed;
}

.header {
    margin-top: 2px;
    padding-bottom: 21px;
    @include full-font($museo-700, 28px, $deep-blue);
}

.errorField {
    border: 1px solid $red;
}

.errorMessage{
    @include full-font($museo-300, 13px, $red);
    text-align: left;
    margin-top: 5px;
}

.disable{
    display: none;
}

.spinnerModal{
    z-index:3;
    position: fixed;
    background: rgba(255, 255, 255, 0.75);;
    height: 100%;
    width: 100%;
}

.spinnerPosition{
    padding-top: 200px;
}

.requiredContainer{
    float: right;
    .asterik{
        color: #387dc0
    }
    .label{
        font-style: italic;
        color: grey
    }
}