@import "../../../index.scss";
@import "../../../styles/standard.module.scss";


.temp {
    @include full-font($museo-300, 18px, $black);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonContainer {
    width: 200px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 8px;
}

.forgotLink {
    @include full-font($museo-500, 13px, $purple);
    width: 236px;
    height: 15px;
    text-align: center;
    margin: auto;
    line-height: 1.15;

    a {
        text-decoration: none;
        color: $purple;
    }

    a:hover {
        text-decoration: underline;
        color: $purple;
    }

    a:active {
        border: solid 2px $purple;
        border-radius: 4px;
        text-decoration: none;
        color: $purple;
    }
}

.lineBreak {
    width: auto;
    border-top: solid 1px #d7d7d7;
    margin-top: 36px;
    margin-bottom: 30px;
}

.heading {
    height: 28px;
    margin: auto;
    @include full-font($museo-500, 28px, #3859a2);
    font-weight: bold;
    line-height: 1;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 20px;
}

.parentsMsg {
    @include full-font($museo-500, 16px, $black);
    text-align: center;
    margin-bottom: 20px;
}

.close {
    height: 22px;
    width: 22px;
    position: absolute;
    color: #444444;;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.errorMessage{
    @include full-font($museo-300, 13px, $red);
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
  }