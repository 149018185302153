@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";


.MyRole {
  @media screen and (max-width: 719px) {
    max-width: none;
    margin-left: 20px;
    margin-right: 20px;
  }
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 470px;
  text-align: center;
  margin-top: 48px;

  .gradeAdd {
    text-align: center;
    box-sizing: border-box;
    margin: 0 0 11px;
    @include full-font($museo-300, 11px, $purple);
    width: auto;
    display: inline-block;
    color: #5a3487;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 2px;
  }

  .gradeAddContainer {
    @include full-font($museo-500, 16px, #333);
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    text-align: center;
  }

  .gradeAddDash {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
    width: 32%;
    height: 1px;
    margin-top: 12px;
    background-color: #e9e9e9;

    .left {
      float: left !important;
    }

    .right {
      float: right !important;
    }
  }

  .grade {
    @media screen and (max-width: 719px) {
      max-width: 275px;
    }
    width: 90%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 35px;
    border-radius: 8px;
    background-color: #fafafa;
    margin-bottom: 30px;

    .gradeContainer {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      display: flex;
      flex: row;
    }

    .gradeInput {
      width: 100%;
    }
  }
}

.dropDown {
  width: calc(95% + 14px);
}

.dropDownButton {
  @include full-font($museo-500, 16px, $basic-grey);
  margin-top: 20px;
  width: 100%;
  color: $grey;
  font-weight: 500;
  line-height: 1;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $basic-grey;
  height: 40px;
  text-align: left;
  box-shadow: none !important;
  padding: 12px 15px;
}

.dropDownButtonBlack {
  @include full-font($museo-500, 16px, $basic-grey);
  margin-top: 20px;
  width: 100%;
  color: black;
  font-weight: 500;
  line-height: 1;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $basic-grey;
  height: 40px;
  text-align: left;
  box-shadow: none !important;
  padding: 12px 15px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dropDownButtonBlack {
  @include full-font($museo-500, 16px, $basic-grey);
  margin-top: 20px;
  width: 100%;
  color: black;
  font-weight: 500;
  line-height: 1;
  border-radius: 8px;
  background-color: $white;
  border: solid 1px $basic-grey;
  height: 40px;
  text-align: left;
  box-shadow: none !important;
  padding: 12px 15px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dropDownMenu {
  width: 30%;
  max-width: 370px;
  position: absolute;
  max-height: 233px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 359px;
  padding: 5px 0;
  font-size: 16px;
  text-align: left;
  border: 1px solid $light-grey;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  background-color: $white;
  padding-left: 5px;
  padding-right: 5px;

  @media (max-width:500px) {
    width: 70%;
    min-width: 0px;
  }

  @media (max-width:1280px) and (min-width:501px) {
    width: 95%;
  }
}

.item {
  padding-left: 10px;
  padding-right: 10px;
  @include full-font($museo-300, 16px, $basic-grey);
  display: block;
  background-color: $white;
  color: $purple-hover !important;
  padding: 12px 15px 12px 15px;
  border-radius: 8px;
}

.item:hover {
  text-decoration: underline;
  color: $white !important;
  background-color: $purple-hover;
}

.selectionInput {
  width: 100%;
}

.close {
  height: 22px;
  width: 22px;
  color: $dark-grey;
  float: right;
  cursor: pointer;
  position: relative;
  top: 26px;
  left: 20px;
}
