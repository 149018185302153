@import "../../index.scss";
@import "../../styles/buttons.module.scss";
@import "../../styles/standard.module.scss";

.SchoolBuilder {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    text-align: center;
    margin-top: 48px;
    

    .label {
        @include full-font($museo-500, 13px, #333333);
        line-height: 1.15;
        width: 84px;
        height: 15px;
        margin-bottom: 8px;
    }

    .warning{
        @include full-font($museo-500, 18px, #808080);
        line-height: 1.15;
        margin-bottom: 8px;
    }

    .header{
        padding-bottom: 50px;
    }
    .inputGrey{
        margin-top: 20px;
    }

    .buttonContainer{
        margin-top: 20px;
    }

    ::placeholder{
        color: $basic-grey
    }

    .alreadyExistsError{
        @include full-font($museo-700, 18px, $basic-grey);
    }

    .spinnerModal{
        max-width: 490px;
    }
    .radioContainer{
        text-align: initial;
    }
    .addressContainer{
        max-width: 450px;
    }
    .errorMessage{
        @media screen and (max-width: 719px) {
            margin-left: 10px;
        }
    }
    table{
        width: 100%;
        tr,td{
            padding-right: 25px;
            @media screen and (max-width: 719px) {
                display: block;
                padding-right: 10px;
                width: 98%;
            }
            width: 50%;
        }
        tr:first-child{
            @media screen and (min-width: 720px) {
                width: 100%;
            }
        }
    }   
}