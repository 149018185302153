@import "./fonts";

//Style names
$museo-100: 'Museo Sans W01_100', Helvetica, Arial, sans-serif;
$museo-300: 'Museo Sans W01_300', Helvetica, Arial, sans-serif;
$museo-500: 'Museo Sans W01_500', Helvetica, Arial, sans-serif;
$museo-700: 'Museo Sans W01_700', Helvetica, Arial, sans-serif;
$museo-900: 'Museo Sans W01_900', Helvetica, Arial, sans-serif;


@mixin full-font($family, $size, $color, $transform: none, $decoration: none) {
  font-family: $family;
  font-size: $size;
  color: $color;
  text-transform: $transform;
  text-decoration: $decoration;
}

$button-border-radius: 20px;

//colors
$dark-grey:#444444;
$light-grey: #d7d7d7;
$dim-grey: #666666;
$white-smoke: #F4F4F4;
$red: #e81111;
$white: #fff;
$black: #000;
$deep-blue: #3859a2;
$basic-grey: #706f6f; 
$daisy-bush: #5A388A;
$purple-hover: #875CCB;
$aqua-spring: #ecf8ec;
$lime-green: #45C044;
$purple: #5a3487;
$grey: #bebebe;
