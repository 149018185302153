@import "../../index.scss";
@import "../../styles/standard.module.scss";

.emailContainer {
  width: 70%;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 401px){
    width: 360px;
  }

  .label {
    @include full-font($museo-500, 13px, #333333);
    line-height: 1.15;
    width: 84px;
    height: 15px;
    margin-bottom: 8px;
  }

}

.errorText {
  @include full-font($museo-700, 16px, $red);
}

.errorMessage{
  @include full-font($museo-300, 13px, $red);
  text-align: left;
  margin-top: 5px;
  cursor: pointer;
}

.hidden{
  visibility: hidden;
  position: absolute;
}