@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.ThankYou {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 48px;
    .header{
        padding-bottom: 50px;
    }
    .warning{
        @include full-font($museo-500, 18px, #808080);
        line-height: 1.15;
        margin-bottom: 50px;
    }
}