@import '../../index.scss';
@import "../../styles/standard.module.scss";

.container {
    padding: 20px;
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }

  .header {
    margin-top: 2px;
    padding-bottom: 21px;
    @include full-font($museo-700, 28px, $deep-blue);
  }

.instructions {
    @include full-font($museo-500, 13px, #333333);
    padding-bottom: 0px;
}

.buttonContainer {
    margin-top: 12px;
    margin-bottom: 28px;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.backToSignIn {
  width: 154px;
  height: 40px;
  padding: 14px 19px;
  border-radius: 100px;
  background-color: #5a3487;
}

.resendLink {
    @include full-font($museo-500, 13px, $purple);
    width: 100%;
    line-height: 20px;
    margin-top: 8px;
    a {
        @include full-font($museo-500, 13px, $purple);
        float: center;
        text-decoration: none;
    }
    .resend:active {
      border: solid 2px $purple;
      border-radius: 4px;
      text-decoration: none;
      color: $purple;
      cursor: pointer;
    }
}

.close {
    height: 22px;
    width: 22px;
    position: absolute;
    color: #444444;;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }