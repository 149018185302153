@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.RegisterPassword {
	padding: 20px;
	height: 100%;
	width: auto;
	margin: auto;
	display: flex;
	flex-direction: column;
	text-align: center;

	.headerText {
		margin-top: 30px;
		padding-bottom: 38px;
		@include full-font($museo-700, 28px, $deep-blue);
	}





	.inputContainer {
    width: 30%;
    max-width: 370px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    @media (max-width:500px) {
      width: 80%;
    }

    @media (max-width:1280px) and (min-width:501px) {
      width: 95%;
    }

    input:focus {
      outline: none;
      border: 2px solid #116eed;
    }

    .inputGrey {
      margin-top: 20px;
      width: 95%;
    }

    .dropDownMenu {
      width: 30%;
      max-width: 370px;
      position: absolute;
      max-height: 233px;
      overflow-y: scroll;
      overflow-x: hidden;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 359px;
      padding: 5px 0;
      font-size: 16px;
      text-align: left;
      border: 1px solid $light-grey;
      border-radius: 8px;
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
      background-clip: padding-box;
      background-color: $white;
      padding-left: 5px;
      padding-right: 5px;
      @media (max-width:500px) {
        width: 70%;
        min-width: 0px;
      }
  
      @media (max-width:1280px) and (min-width:501px) {
        width: 95%;
      }
    }

    .noSchoolFound {
      @include full-font($museo-500, 16px, $basic-grey);
      margin-top: 10px;
      width: 120%;
      line-height: 35px;
      margin-left: -15%;
      padding: 12px 15px 12px 30px;
      box-shadow: 0 5px 6px 5px rgba(0,0,0,0.1);
      text-align: left;
      border-radius: 10px;
      @media (max-width: 500px){
        width: 90%;
        margin-left: -2%;
      }
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: #F5F5F5;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $grey;
    }
    
    
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #fafafa;
      border: solid 1px $light-grey;
    }
  }

	.passReq{
		@include full-font($museo-500, 13px, $basic-grey);
    box-sizing: border-box;
    text-align: left;
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
	}

	.tosContainer{
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		.tosText{
			@include full-font($museo-500, 13px, $basic-grey);
			text-transform: capitalize;
			text-align: left;
			line-height: 20px;
			margin-left: 5px;
			a{
				@include full-font($museo-300, 13px, #50aafc);
			}
		}
	}

	

}

.eye{
  position: absolute;
  margin: 30px 20px 20px 340px;

  @media (max-width:510px) {
    margin-left: 65%;
  }
}

.popup:before {
  content: "";
  position: absolute;
  top: calc(100% - 154px); //top: calc(100% - 197px);
  left: 130px;
  height: 15px;
  width: 15px;
  background: $white;
  transform: rotate(-135deg);
  border-bottom:inherit;
  border-right:inherit;
}

.popup {
  top: 18px !important;
  color: $black;
  text-align: left;
  background-color: $white;
  padding: 24px;
  width: 232px;
  height: 97px; //137px
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e3e3e3;
  border-radius: 5px;

  .listItem{
    margin-top: 3px;
    margin-bottom: 5px;
    width:100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    height: 15px;
  }
  .checkmark{
    height:8px;
    width:6px;
    margin-right: 10px;
  }
  .pleaseText{
    height: 15px;
    @include full-font($museo-500, 13px, $red);
    font-weight: 500;
    line-height: 1.15;
    padding-bottom: 12px;
  }
  .bullet{
    padding-top: 4px;
    margin-right:10px;
    height:3px;
    width:3px;
  }
  .createText{
    margin-bottom: 8px;
    margin-top: 12px;
  }
  .popOverText{
    margin-top: 12px;
    height: 15px;
    @include full-font($museo-300, 13px, $dark-grey);
    line-height: 1.15;
    .success{
      color: #008018;
    }
  }
}