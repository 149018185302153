@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=381f9130-dfe9-48ee-8af8-d19b9ed5603d");
@font-face {
  font-family: "Museo W01_100";
  src: url("/my-scholastic/sign-in/resources/museo/bd70b185-ad25-4819-b7e9-2d6ebe2cf722.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/bd70b185-ad25-4819-b7e9-2d6ebe2cf722.eot?#iefix") format("eot"), url("/resources/museo/71e645d2-276d-4568-b9e4-e215b8e5b24f.woff2") format("woff2"), url("/resources/museo/65bf816d-089f-4500-aad4-72a4f160b487.woff") format("woff"), url("/resources/museo/ff43203c-5008-4e1d-86fc-48c3d0f8e1b2.ttf") format("truetype"), url("/resources/museo/eaf744fb-4131-4966-b507-d0474d384707.svg#eaf744fb-4131-4966-b507-d0474d384707") format("svg"); }

@font-face {
  font-family: "Museo W01_300";
  src: url("/my-scholastic/sign-in/resources/museo/33e3dd38-a7c3-4a78-9660-90a0e7406419.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/33e3dd38-a7c3-4a78-9660-90a0e7406419.eot?#iefix") format("eot"), 
        url("/my-scholastic/sign-in/resources/museo/d9dabe05-624c-4f28-8eee-b3b6f1841abf.woff2") format("woff2"), 
        url("/my-scholastic/sign-in/resources/museo/827f2f08-a8d3-40b2-9795-927b59905269.woff") format("woff"), 
        url("/my-scholastic/sign-in/resources/museo/92143848-9290-4516-80fc-ebf83b053307.ttf") format("truetype"), 
        url("/my-scholastic/sign-in/resources/museo/b0d9d7a5-5c5b-439c-afa5-2c875dcf8ea2.svg#b0d9d7a5-5c5b-439c-afa5-2c875dcf8ea2") format("svg"); }

@font-face {
  font-family: "Museo W01_700";
  src: url("/my-scholastic/sign-in/resources/museo/3b3d99a2-6b36-4912-a93e-29277020a5cf.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/3b3d99a2-6b36-4912-a93e-29277020a5cf.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/13012335-73ef-44f0-b295-7b83041355af.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/28d74e9b-4ea9-4e3c-b265-c67a72c66856.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/b432b4e1-014a-4ed8-865c-249744f856b0.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/8e63fd1e-adc9-460e-9ef7-bbf98ee32a71.svg#8e63fd1e-adc9-460e-9ef7-bbf98ee32a71") format("svg"); }

@font-face {
  font-family: "Museo Sans W01_100";
  src: url("/my-scholastic/sign-in/resources/museo/e14e9fd6-0631-4491-b873-211c7b6f926c.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/e14e9fd6-0631-4491-b873-211c7b6f926c.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/fd9d5b5d-71c1-4db0-bf20-cd7e3ce4d069.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/e976d680-12b6-411e-9f79-0ef9287f484d.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/81780024-f1ae-4135-a0ae-d0bab2c19986.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/98fa899e-fa41-4563-b67a-33d373ba60ee.svg#98fa899e-fa41-4563-b67a-33d373ba60ee") format("svg"); }

@font-face {
  font-family: "Museo Sans W01_300";
  src: url("/my-scholastic/sign-in/resources/museo/fd479df2-01b0-4e3a-a411-915c477f1352.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/fd479df2-01b0-4e3a-a411-915c477f1352.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/1d3191cd-ae8c-45e2-bb04-11e96d8fa974.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/b252d464-65cb-4950-88f7-ac0a1bf79b75.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/b3d18117-6517-4d3f-afc1-774086ff5fc1.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/b9bed509-0816-43d8-b14b-7525a21f4bde.svg#b9bed509-0816-43d8-b14b-7525a21f4bde") format("svg"); }

@font-face {
  font-family: "Museo Sans W01_500";
  src: url("/my-scholastic/sign-in/resources/museo/a29947fd-0c53-492b-b2f4-399b01153b30.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/a29947fd-0c53-492b-b2f4-399b01153b30.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/d9896899-f08c-4750-a874-a9e10d83c2cb.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/d42dd843-62b0-4623-8855-d5882512c3c9.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/6d532ccb-086c-4000-bab2-5d16862fcf01.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/f56b7407-f97b-4cb3-9ce6-4e13065eec93.svg#f56b7407-f97b-4cb3-9ce6-4e13065eec93") format("svg"); }

@font-face {
  font-family: "Museo Sans W01_700";
  src: url("/my-scholastic/sign-in/resources/museo/3d0a39cf-115c-4800-9b1e-31c7912abfc2.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/3d0a39cf-115c-4800-9b1e-31c7912abfc2.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/e2d37f34-6bca-473c-b64d-93811f4dc046.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/eadbb595-a394-48fc-834f-8bf5da15cddf.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/9bcba093-b507-4b9b-9c83-303003d3815c.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/9a2af429-2624-4bd1-a676-8f0eed3e7e31.svg#9a2af429-2624-4bd1-a676-8f0eed3e7e31") format("svg"); }

@font-face {
  font-family: "Museo Sans W01_900";
  src: url("/my-scholastic/sign-in/resources/museo/7e90c1ee-e4b1-46f0-9134-93a8ba421545.eot?#iefix");
  src: url("/my-scholastic/sign-in/resources/museo/7e90c1ee-e4b1-46f0-9134-93a8ba421545.eot?#iefix") format("eot"), url("/my-scholastic/sign-in/resources/museo/009ca58d-46af-4651-a359-f0ae2301085b.woff2") format("woff2"), url("/my-scholastic/sign-in/resources/museo/3619b5f4-8df9-44f5-8c53-b7c236e9c74e.woff") format("woff"), url("/my-scholastic/sign-in/resources/museo/9b2d949a-4e1c-435c-8412-f377c80583ae.ttf") format("truetype"), url("/my-scholastic/sign-in/resources/museo/7a319e11-05eb-49ce-bdb7-f692766e6477.svg#7a319e11-05eb-49ce-bdb7-f692766e6477") format("svg"); }
