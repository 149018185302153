@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.MySchool {
  @media screen and (max-width: 719px) {
    max-width: none;
    margin-left: 20px;
    margin-right: 20px;
  }

  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 470px;
  text-align: center;
  margin-top: 48px;

  .choiceGroup {
    display: flex;
    flex-direction: column;

    .choice {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    a {
      text-align: left;
      margin-bottom: 5px;
      font-weight: bold;
      display: inline;
      padding-left: 18px;
      @include full-font($museo-700, 19px, #919191);
    }

    input {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 8px;
      padding-left: 18px;
      border-radius: 10px;
      border: solid 1px #d7d7d7;
      background-color: #fff;
      color: #5f92c9;
    }
  }

  .notFoundLink {
    cursor: pointer;
    @include full-font($museo-500, 13px, $purple);
    line-height: 16px;
    margin-top: 20px;
  }

}