@import "../../../index.scss";
@import "../../../styles/standard.module.scss";

.eye{
  position: absolute;
  margin: 30px 20px 20px 340px;

  @media (max-width:510px) {
    margin-left: 65%;
  }
}

.popup:before {
  content: "";
  position: absolute;
  top: calc(100% - 154px); //top: calc(100% - 197px);
  left: 130px;
  height: 15px;
  width: 15px;
  background: $white;
  transform: rotate(-135deg);
  border-bottom:inherit;
  border-right:inherit;
}

.popup {
  top: 18px !important;
  color: $black;
  text-align: left;
  background-color: $white;
  padding: 24px;
  width: 232px;
  height: 97px; //137px
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e3e3e3;
  border-radius: 5px;

  .listItem{
    margin-top: 3px;
    margin-bottom: 5px;
    width:100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    height: 15px;
  }
  .checkmark{
    height:8px;
    width:6px;
    margin-right: 10px;
  }
  .pleaseText{
    height: 15px;
    @include full-font($museo-500, 13px, $red);
    font-weight: 500;
    line-height: 1.15;
    padding-bottom: 12px;
  }
  .bullet{
    padding-top: 4px;
    margin-right:10px;
    height:3px;
    width:3px;
  }
  .createText{
    margin-bottom: 8px;
    margin-top: 12px;
  }
  .popOverText{
    margin-top: 12px;
    height: 15px;
    @include full-font($museo-300, 13px, $dark-grey);
    line-height: 1.15;
    .success{
      color: #008018;
    }
  }
}

.container {
  padding: 20px;
  height: 100%;
  width: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;


  .headerText {
    margin-top: 30px;
    padding-bottom: 38px;
    @include full-font($museo-700, 28px, $deep-blue);
  }

  .selectionText {
    @include full-font($museo-700, 18px, $basic-grey);
  }

  .errorText {
    @include full-font($museo-700, 16px, $red);
  }

  .errorMessage{
    @include full-font($museo-300, 13px, $red);
    text-align: left;
    margin-top: 5px;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @include full-font($museo-500, 16px, $light-grey);
  }
    
  .inputContainer {
    width: 30%;
    max-width: 370px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    @media (max-width:500px) {
      width: 80%;
    }

    @media (max-width:1280px) and (min-width:501px) {
      width: 95%;
    }

    input:focus {
      outline: none;
      border: 2px solid #116eed;
    }

    .inputGrey {
      margin-top: 20px;
      width: 95%;
    }

    .dropDownMenu {
      width: 30%;
      max-width: 370px;
      position: absolute;
      max-height: 233px;
      overflow-y: scroll;
      overflow-x: hidden;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 359px;
      padding: 5px 0;
      font-size: 16px;
      text-align: left;
      border: 1px solid $light-grey;
      border-radius: 8px;
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
      background-clip: padding-box;
      background-color: $white;
      padding-left: 5px;
      padding-right: 5px;
      @media (max-width:500px) {
        width: 70%;
        min-width: 0px;
      }
  
      @media (max-width:1280px) and (min-width:501px) {
        width: 95%;
      }
    }

    .noSchoolFound {
      @include full-font($museo-500, 16px, $basic-grey);
      margin-top: 10px;
      width: 120%;
      line-height: 35px;
      margin-left: -15%;
      padding: 12px 15px 12px 30px;
      box-shadow: 0 5px 6px 5px rgba(0,0,0,0.1);
      text-align: left;
      border-radius: 10px;
      @media (max-width: 500px){
        width: 90%;
        margin-left: -2%;
      }
    }

    ::-webkit-scrollbar {
      width: 12px;
      background-color: #F5F5F5;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $grey;
    }
    
    
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #fafafa;
      border: solid 1px $light-grey;
    }
  }

  .lineBreak {
    padding-top: 20px;
    margin: auto;
    width: 100%;
    max-width: 970px;
    text-align: center;
    border-bottom: 1px solid $light-grey;
  }

  .loading{
    margin-top: 20px;
    @include full-font($museo-300, 16px, $black);
  }

  .contactText {
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 25%;
    padding-top: 40px;
    line-height: 1.54;
    @include full-font($museo-700, 13px, $basic-grey);

    a {
      @include full-font($museo-700, 18px, $basic-grey, none, underline);

      &:hover {
        color: $basic-grey;
      }
    }

    @media (max-width:720px) {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  .buttonContainer{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .dropDown{
    width: calc(95% + 14px);
  }

  .dropDownButton{
    @include full-font($museo-500, 16px, $basic-grey);
    margin-top: 20px;
    width: 100%;
    color: $grey;
    font-weight: 500;
    line-height: 1;
    border-radius: 8px;
    background-color: $white;
    border: solid 1px $basic-grey;
    height: 40px;
    text-align: left;
    box-shadow: none!important;
    padding: 12px 15px;
  }

  .dropDownButtonBlack{
    @include full-font($museo-500, 16px, $basic-grey);
    margin-top: 20px;
    width: 100%;
    color: black;
    font-weight: 500;
    line-height: 1;
    border-radius: 8px;
    background-color: $white;
    border: solid 1px $basic-grey;
    height: 40px;
    text-align: left;
    box-shadow: none!important;
    padding: 12px 15px;
    white-space:nowrap;
    overflow-y: hidden;
    overflow-x:hidden; 
  }

  .menu{
    display: flex;
    flex-direction: column;
  }

  .caret{
    height: 16px;
    width: 10px;
    font-size: 14px;
    font-weight: bold;
    float: right;
  }

  .item{
    padding-left: 10px;
    padding-right: 10px;
    @include full-font($museo-300, 16px, $basic-grey);
    display: block;
    background-color: $white;
    color: $purple-hover!important;
    padding: 12px 15px 12px 15px;
    border-radius: 8px;
  }

  .item:hover{
    text-decoration: underline;
    color: $white !important;
    background-color: $purple-hover;
  }

  .tokenError {
    @include full-font($museo-300, 14px, $red);
  }
}

.close {
  height: 22px;
  width: 22px;
  position: absolute;
  color: #444444;;
  top: 15px;
  right: 15px;
  cursor: pointer;
}