@import "./_constants";

.button {
  border: 1px solid $black;
  border-radius: $button-border-radius;
  width: 134px;
  padding: auto;
  height: 40px;
  margin: 20px;
  outline: 0;
  letter-spacing: 1.5px;
  background-color: $black;
  cursor: pointer;
  @include full-font($museo-700, 11px, $white, uppercase, none);

  &.purple {
    background-color: $daisy-bush;
    border: 1px solid $daisy-bush;

    &:hover {
      border-color: $purple-hover;
      background-color: $purple-hover;
    }

    &:disabled {
      background-color: $basic-grey;
      border-color: transparent;
      cursor: not-allowed;
    }

    &:active {
      box-shadow: 0 0 0 3px white, 0 0 0 6px $purple-hover;
    }

    &:focus {
      box-shadow: 0 0 0 3px white, 0 0 0 6px $purple-hover;
    }
  }
}

.buttonOutlined {
  border: 1px solid $black;
  border-radius: $button-border-radius;
  background-color: $white;
  width: 160px;
  padding: auto;
  height: 40px;
  margin: 20px;
  outline: 0;
  letter-spacing: 1.5px;
  cursor: pointer;
  @include full-font($museo-700, 11px, $black, uppercase, none);

  &.purple {
    border: 1px solid $daisy-bush;
    @include full-font($museo-700, 11px, $daisy-bush, uppercase, none);

    &:hover {
      border-color: $purple-hover;
      background-color: $purple-hover;
      color: $white;
    }
  
    &:disabled {
      background-color: $basic-grey;
      border-color: transparent;
      cursor: not-allowed;
    }

    &:active {
      box-shadow: 0 0 0 3px white, 0 0 0 6px $purple-hover;
    }

    &:focus {
      box-shadow: 0 0 0 3px white, 0 0 0 6px $purple-hover;
    }
  }
}
