.Spinner {
    .loading_green {
        margin: auto;
        width: 70px;
        height:70px;
        border-radius: 50%;
        border: 4px solid #f4f4f4;
        border-top: 4px solid #9bdf95;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      
    @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
      
}