@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.KidInfo {
    @media screen and (max-width: 719px) {
        max-width: none;
        margin-left: 20px;
        margin-right: 20px;
    }
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px;
 
    .errorMessage{
        text-align: center;
    }

    .studentFormDisclaimer {
        text-align: center;
        @include full-font($museo-500, 16px, $basic-grey);
    }

    .kidInfoContainer {
        padding: 30px;
        border-radius: 18px;
        text-align: center;
        margin-top: 28px;
        background-color: #fafafa
    }

    .skipStepLink {
        @include full-font($museo-700, 11px, #5a3487);
        text-transform: none;
        text-decoration: none;
        letter-spacing: 2px;
        margin-top: 30px;
        text-align: center;
        cursor: pointer;
    }

    .btnKidAdd {
        @include full-font($museo-700, 11px, #5a3487);
        text-transform: none;
        text-decoration: none;
        letter-spacing: 2px;
        margin-top: 30px;
        text-align: center;
        cursor: pointer;

    }

    .btnKidAdd::before {
        right: 10px;
    }

    .btnKidAdd:after {
        left: 10px;
    }

    .btnKidAdd::before,
    .btnKidAdd::after {
        background-color: #d7d7d7;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 30%;
    }

    .header {
        padding-bottom: 50px;
    }

    .inputGrey {
        margin-top: 20px;
        width: calc(100% - 15px);
    }

    .btnClose {
        position: relative;
        width: 12.7px;
        left: 15.7px;
        float: right;
        cursor: pointer;
    }

    .buttonContainer {
        margin-top: 5px;
    }

    ::placeholder {
        color: $basic-grey
    }

    .alreadyExistsError {
        @include full-font($museo-700, 18px, $basic-grey);
    }

    .spinnerModal {
        max-width: 490px;
    }
}