@import "./styles/constants";
@import "./styles/buttons.module.scss";

html {
  height: 100vh;
}

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, $museo-500, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#oo_tab {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
