@import "../../index.scss";

.footer {
  text-align: center;
  margin: 40px 0px 36px 0px;
  display: block;

  .footerLink {
    a {
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      padding: 4px;
      border: solid 2px white;
      border-radius: 8px;
    }

    a:hover {
      text-decoration: underline;
    }

    a:active {
      border: solid 2px $purple;
      border-radius: 8px;
      text-decoration: none;
    }

    .privacyLink {
      @include full-font($museo-700, 16px, $purple);
      margin-right: 6px;
    }

    .termsOfUse {
      @include full-font($museo-300, 14px, $purple);
      margin-left: 6px;
    }
  }

  .copyright {
    @include full-font($museo-300, 12px, #424242);
    margin-top: 12px;
    line-height: 22px;
  }
}