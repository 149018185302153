@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";


.mainContainer{
    display: flex;
    flex-direction: column;
    h1{
        margin-left: auto;
        margin-right: auto;
    }
    
}

.forgotLink {
    @include full-font($museo-500, 13px, $purple);
    width: 236px;
    height: 15px;
    text-align: center;
    margin: auto;
    line-height: 1.15;
    
    .link{
      text-decoration: none;
      color: $purple;
      cursor: pointer;
    }

    .link:hover {
      text-decoration: underline;
      color: $purple;
      cursor: pointer;
    }

    .link:active {
      border: solid 2px $purple;
      border-radius: 4px;
      text-decoration: none;
      color: $purple;
      cursor: pointer;
    }

    .link:focus {
      border: solid 2px $purple;
      border-radius: 4px;
      text-decoration: none;
      color: $purple;
      cursor: pointer;
    }
}

.container {
    padding: 20px;
    height: 100%;
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;

    .reCaptcha {
        display: flex;
        justify-content: center;
        margin-top: 26px;
        margin-bottom: 10px;
      }
    .emailContainer{
        display: flex;
        padding-right: 45px;
        padding-left: 45px;
        flex-direction: row;
        margin-bottom: 18px;
        @include full-font($museo-500, 13px, $dark-grey);
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        max-width: 370px;
        text-align: left;
        a {
          text-decoration: none;
        }
        .change{
            color: $purple;
            margin-left: 5px;
            cursor: pointer;
        }
        @media(max-width: 450px){
          padding-left: 10%;
          padding-right: 0px;
        }
    }
    .labelContainer{
        width: 95%;
        max-width: 370px;
        text-align: left;
        padding-right: 45px;
        padding-left: 45px;
        margin-left: auto;
        margin-right: auto;
        .label {
            width: 58px;
            height: 15px;
            @include full-font($museo-500, 13px, $dark-grey);
            line-height: 1.15;
        }
        @media(max-width: 450px){
          padding-left: 10%;
          padding-right: 0px;
        }
    }
    .headerText {
      margin-top: 30px;
      padding-bottom: 38px;
      @include full-font($museo-700, 28px, $deep-blue);
    }
  
    .selectionText {
      @include full-font($museo-700, 18px, $basic-grey);
    }
  
    .errorText {
      @include full-font($museo-700, 16px, $red);
    }
  
    .errorMessage{
      @include full-font($museo-300, 13px, $red);
      text-align: left;
      margin-top: 5px;
      cursor: pointer;
    }
  
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include full-font($museo-500, 16px, $light-grey);
    }

    .input {
        width: 360px;
        height: 40px;
        margin: 8px 0 0;
        border-radius: 5px;
        border: solid 1px #919191;
        background-color: #ffffff;
      }
      
    .inputContainer {
      width: 30%;
      max-width: 370px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
  
      @media (max-width:500px) {
        width: 80%;
      }
  
      @media (max-width:1280px) and (min-width:501px) {
        width: 95%;
      }
  
      input:focus {
        outline: none;
        border: 2px solid #116eed;
      }
      
      .inputGrey {
        margin-top: 8px;
        width: 95%;
      }
  
    }
  
    .loading{
      margin-top: 20px;
      @include full-font($museo-300, 16px, $black);
    }
  
    .contactText {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 25%;
      padding-top: 40px;
      line-height: 1.54;
      @include full-font($museo-700, 13px, $basic-grey);
  
      a {
        @include full-font($museo-700, 18px, $basic-grey, none, underline);
  
        &:hover {
          color: $basic-grey;
        }
      }
  
      @media (max-width:720px) {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      }
    }
  
  }

  .close {
    height: 22px;
    width: 22px;
    position: absolute;
    color: #444444;;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

.spinnerPosition{
  padding-right: 35px;
}

.hidden{
  visibility: hidden;
  position: absolute;
}