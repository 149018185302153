@import "../../../index.scss";
@import "../../../styles/buttons.module.scss";
@import "../../../styles/standard.module.scss";

.MyInfo {
    @media screen and (max-width: 719px) {
        max-width: none;
        margin-left: 20px;
        margin-right: 20px;
    }
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 470px;
    text-align: center;
    margin-top: 48px;

    .label {
        @include full-font($museo-500, 13px, #333333);
        line-height: 1.15;
        width: 84px;
        height: 15px;
        margin-bottom: 8px;
    }

    .header{
        padding-bottom: 50px;
    }

    .inputGrey{
        margin-top: 20px;
    }

    .buttonContainer{
        margin-top: 20px;
    }

    ::placeholder{
        color: $basic-grey
    }

    .alreadyExistsError{
        @include full-font($museo-700, 18px, $basic-grey);
    }

    .spinnerModal{
        max-width: 490px;
    }
}